<template>
  <div>
    <!-- 头部区域 -->
    <div class="title">
      <div class="title-left">
        <div class="title-left-contentOne">
          【{{ archiveInfo.arrangeModel | arrangeModelFilters }}】{{ archiveInfo.examName }}
        </div>
        <div class="title-left-contentTwo">({{ archiveInfo.examCode }})</div>
      </div>
      <div class="title-right">
        <el-button
          v-throttle
          @click="
            () => {
              this.$router.back();
            }
          "
          style="
            color: rgb(24, 144, 255);
            border-color: rgb(24, 144, 255);
            border-radius: 5px;
          "
          >关闭</el-button
        >
      </div>
    </div>
    <div style="margin-top: 20px">
      <el-tabs v-model="activeName">
        <el-tab-pane label="考试数据" name="first">
          <!-- 表格第一行 -->
          <div class="cardBox">
            <div class="cardTit">
              <i
                :class="showArr[0] ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
                @click="toggleBtn(0)"
              ></i>
              <span>1</span>
              基本规则
            </div>
            <div class="cardContent" :style="showArr[0] ? '' : 'height:0'">
              <!-- 内容 -->
              <table border="1">
                <tr>
                  <td class="bgColor">考试名称</td>
                  <td colspan="4">{{ examBasicInfo.examName }}</td>
                </tr>
                <tr>
                  <td class="bgColor">考试时间</td>
                  <td colspan>{{ examBasicInfo.examStartTime | dayList }}</td>
                  <td class="bgColor">设置试卷</td>
                  <td colspan>
                    {{ examBasicInfo.examModel | examModelFilters }}
                  </td>
                </tr>
                <tr>
                  <td class="bgColor" rowspan="4">考试科目</td>
                </tr>
                <tr
                  v-for="item in examBasicInfo.examArchivesExamSubjectViewList"
                  :key="item.subjectType"
                >
                  <td>{{ item.subjectType | subjectType }}</td>
                  <td colspan="2">
                    <div
                      class="divText"
                      v-for="(val, i) in item.sysSrgOperationTestList"
                      :key="i"
                    >
                      {{ i + 1 }}.{{ val }}
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <!-- 表格第二行 -->
          <div class="cardBox">
            <div class="cardTit">
              <i
                :class="showArr[1] ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
                @click="toggleBtn(1)"
              ></i>
              <span>2</span>
              考试规则
            </div>
            <div class="cardContent" :style="showArr[1] ? '' : 'height:0'">
              <!-- 内容 -->
              <div class="cardContentRow">
                排考方式：{{
                  examBasicInfo.examArchivesExamRuleView?.arrangeModel
                    | arrangeModelFilters
                }}
              </div>
              <div class="cardContentRow">
                答题时间：
                {{ examBasicInfo.examArchivesExamRuleView?.examAnswerTime }} 分钟
              </div>
              <!-- <div class="cardContentRow">
                考试模式：
                {{
                  examBasicInfo.examArchivesExamRuleView?.examModel === 1
                    ? "无试卷"
                    : "电子试卷"
                }}
              </div> -->
            </div>
          </div>
          <!-- 表格第三行 -->
          <div class="cardBox">
            <div class="cardTit">
              <i
                :class="showArr[2] ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
                @click="toggleBtn(2)"
              ></i>
              <span>3</span>
              考场安排
            </div>
            <div class="cardContent" :style="showArr[2] ? '' : 'height:0'">
              <!-- 内容 -->
              <el-table
                :data="examBasicInfo.examArchivesExamPlaceViewList"
                border
                width="100%"
                height="400px"
                :header-cell-style="{
                  'text-align': 'center',
                  backgroundColor: '#dfe6ec',
                }"
                :cell-style="{ 'text-align': 'center' }"
              >
                <el-table-column
                  prop="sysOrgAreaName"
                  label="所属区县"
                ></el-table-column>
                <el-table-column
                  prop="sysOrgExamPlaceName"
                  label="所属考点"
                ></el-table-column>
                <el-table-column
                  prop="sysOrgExamPlaceRoomName"
                  label="考场名称"
                ></el-table-column>
                <el-table-column prop="subjectType" label="考试科目">
                  <template slot-scope="scope">{{
                    scope.row.subjectType | subjectType
                  }}</template>
                </el-table-column>
                <el-table-column
                  prop="examExamineeNum"
                  label="考试人次"
                ></el-table-column>
                <el-table-column
                  prop="examSessionCount"
                  label="安排场次"
                ></el-table-column>
              </el-table>
            </div>
          </div>
          <!-- 表格第四行 -->
          <div class="cardBox">
            <div class="cardTit">
              <i
                :class="showArr[3] ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
                @click="toggleBtn(3)"
              ></i>
              <span>4</span>
              场次时间
            </div>
            <div class="cardContent" :style="showArr[3] ? '' : 'height:0'">
              <!-- 内容 -->
              <el-form inline :model="tableCondition">
                <el-form-item label="所属地区" class="formItemBoxStyle">
                  <el-select
                    v-model="tableCondition.area"
                    @change="handleQuery"
                  >
                    <el-option label="全部" value=""></el-option>
                    <el-option
                      v-for="(item, i) in areaList"
                      :key="i"
                      :value="item"
                      :label="item"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="所属考点" class="formItemBoxStyle">
                  <el-input
                    v-model="tableCondition.examPlace"
                    placeholder="请输入"
                    @input="handleQuery"
                  ></el-input>
                </el-form-item>
                <el-form-item label="所属考场" class="formItemBoxStyle">
                  <el-select
                    v-model="tableCondition.examPlaceRoom"
                    @change="handleQuery"
                  >
                    <el-option label="全部" value=""></el-option>
                    <el-option
                      v-for="(item, i) in examPlaceRoomList"
                      :key="i"
                      :value="item"
                      :label="item"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-form>
              <el-table
                :data="tableData"
                border
                :header-cell-style="{
                  'text-align': 'center',
                  backgroundColor: '#dfe6ec',
                }"
                :cell-style="{ 'text-align': 'center' }"
                height="400px"
              >
                <el-table-column
                  label="所属地区"
                  prop="sysOrgAreaName"
                ></el-table-column>
                <el-table-column
                  label="所属考点"
                  prop="sysOrgExamPlaceName"
                ></el-table-column>
                <el-table-column
                  label="考场名称"
                  prop="sysOrgExamPlaceRoomName"
                ></el-table-column>
                <el-table-column label="考场科目" prop="subjectType">
                  <template slot-scope="scope">
                    {{ scope.row.subjectType | subjectType }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="考场场次"
                  prop="examSession"
                ></el-table-column>
                <el-table-column
                  label="开考时间"
                  prop="examStartTime"
                  :formatter="customFormatter"
                ></el-table-column>
                <el-table-column
                  label="结束时间"
                  prop="examEndTime"
                  :formatter="customFormatter"
                ></el-table-column>
                <el-table-column
                  label="考试人次"
                  prop="examExamineeNum"
                ></el-table-column>
              </el-table>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="教师数据" name="second">
          <el-form inline :model="teacherCondition">
            <el-form-item label="教师名称" class="formItemBoxStyle">
              <el-input
                v-model.trim="teacherCondition.teacherName"
                placeholder="请输入"
                @input="handleQueryTeacher"
              ></el-input>
            </el-form-item>
            <el-form-item label="编码" class="formItemBoxStyle">
              <el-input
                v-model.trim="teacherCondition.teacherCode"
                placeholder="请输入"
                @input="handleQueryTeacher"
              ></el-input>
            </el-form-item>
            <el-form-item label="学校" class="formItemBoxStyle">
              <el-input
                v-model.trim="teacherCondition.schoolName"
                placeholder="请输入"
                @input="handleQueryTeacher"
              ></el-input>
            </el-form-item>
            <el-form-item label="考试科目" class="formItemBoxStyle">
              <el-select
                v-model="teacherCondition.subjectType"
                placeholder="请选择"
                @change="handleQueryTeacher"
              >
                <el-option label="全部" :value="0"></el-option>
                <el-option label="物理" :value="1"></el-option>
                <el-option label="生物" :value="2"></el-option>
                <el-option label="化学" :value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="身份" class="formItemBoxStyle">
              <el-select
                v-model="teacherCondition.teacherType"
                placeholder="请选择"
                @change="handleQueryTeacher"
              >
                <el-option label="全部" :value="0"></el-option>
                <el-option label="监考组长" :value="1"></el-option>
                <el-option label="监考老师" :value="2"></el-option>
                <el-option label="阅卷组长" :value="3"></el-option>
                <el-option label="阅卷老师" :value="4"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleQueryTeacher"
                >查询</el-button
              >
              <el-button @click="handleResetTeacher">重置</el-button>
            </el-form-item>
          </el-form>
          <el-table
            :data="teacherData"
            border
            :header-cell-style="{
              'text-align': 'center',
              backgroundColor: '#dfe6ec',
            }"
            :cell-style="{ 'text-align': 'center' }"
          >
            <el-table-column label="科目">
              <template slot-scope="scope">
                {{ scope.row.subjectType | subjectType }}
              </template>
            </el-table-column>
            <el-table-column
              label="教师名称"
              prop="teacherName"
            ></el-table-column>
            <el-table-column
              label="用户编码"
              prop="teacherCode"
            ></el-table-column>
            <el-table-column
              label="区县"
              prop="sysOrgAreaName"
            ></el-table-column>
            <el-table-column
              label="学校"
              prop="sysOrgSchoolName"
            ></el-table-column>
            <el-table-column label="身份">
              <template slot-scope="scope">
                {{ scope.row.teacherType | teacherTypeFilter }}
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            style="margin-top: 10px"
            @size-change="handleSizeChangeTeacherPage"
            @current-change="handleCurrentChangeTeacherPage"
            :current-page="teacherPage.pageIndex"
            :page-sizes="[10, 20, 50, 100, 150, 200]"
            :page-size="teacherPage.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="teacherPage.totalSize"
          ></el-pagination>
        </el-tab-pane>
        <el-tab-pane label="学生数据" name="third">
          <el-form inline :model="studentCondition">
            <el-form-item label="考生姓名" class="formItemBoxStyle">
              <el-input
                v-model.trim="studentCondition.studentName"
                placeholder="请输入"
                @input="handleQueryStudent"
              ></el-input>
            </el-form-item>
            <el-form-item label="考号" class="formItemBoxStyle">
              <el-input
                v-model.trim="studentCondition.examineeCode"
                placeholder="请输入"
                @input="handleQueryStudent"
              ></el-input>
            </el-form-item>
            <el-form-item label="学校" class="formItemBoxStyle">
              <el-input
                v-model.trim="studentCondition.sysOrgSchoolName"
                placeholder="请输入"
                @input="handleQueryStudent"
              ></el-input>
            </el-form-item>
            <el-form-item label="考试科目" class="formItemBoxStyle">
              <el-select
                v-model="studentCondition.subjectType"
                @change="handleQueryStudent"
              >
                <el-option label="全部" :value="0"></el-option>
                <el-option label="物理" :value="1"></el-option>
                <el-option label="生物" :value="2"></el-option>
                <el-option label="化学" :value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleQueryStudent"
                >查询</el-button
              >
              <el-button @click="handleResetStudent">重置</el-button>
            </el-form-item>
          </el-form>
          <el-table
            :data="stuData"
            border
            :header-cell-style="{
              'text-align': 'center',
              backgroundColor: '#dfe6ec',
            }"
            :cell-style="{ 'text-align': 'center' }"
          >
            <el-table-column label="考生姓名">
              <template slot-scope="scope">
                <span
                  style="color: blue; cursor: pointer"
                  @click="handleExamineeNameClick(scope.row)"
                  >{{ scope.row.studentName }}</span
                >
              </template>
            </el-table-column>
            <el-table-column label="考号" prop="examineeCode"></el-table-column>
            <el-table-column
              label="区县"
              prop="sysOrgAreaName"
            ></el-table-column>
            <el-table-column
              label="学校"
              prop="sysOrgSchoolName"
            ></el-table-column>
            <el-table-column
              label="班级"
              prop="sysOrgSchoolClassName"
            ></el-table-column>
            <el-table-column
              label="考试批次"
              prop="examSessionInfo"
            ></el-table-column>
            <el-table-column label="座位号" prop="seatNum"></el-table-column>
            <el-table-column label="科目">
              <template slot-scope="scope">
                {{ scope.row.subjectType | subjectType }}
              </template>
            </el-table-column>
            <el-table-column
              label="考题"
              prop="sysOrgOperationTestName"
            ></el-table-column>
            <el-table-column label="考试状态">
              <template slot-scope="scope">
                {{ scope.row.examineeStatus | examineeStatusFilter }}
              </template>
            </el-table-column>
            <el-table-column label="成绩" prop="score" :formatter="customFormatter"></el-table-column>
            <el-table-column label="考生视频">
              <template slot-scope="scope">
                <span
                  v-if="scope.row.examineeStatus !== 4 && scope.row.examineeStatus !== 5"
                  style="color: blue; cursor: pointer"
                  @click="handleCheckExamData(scope.row, 0)"
                  >查看</span
                >
                <span v-else>/</span>
              </template>
            </el-table-column>
            <el-table-column label="考试数据">
              <template slot-scope="scope">
                <span
                  v-if="scope.row.examineeStatus !== 4 && scope.row.examineeStatus !== 5"
                  style="color: blue; cursor: pointer"
                  @click="handleCheckExamData(scope.row, 1)"
                  >查看</span
                >
                <span v-else>/</span>
              </template>
            </el-table-column>
            <el-table-column
              label="阅卷老师"
              prop="markTeacherName"
            >
            <template slot-scope="scope">
                {{ (scope.row.examineeStatus !== 4 &&  scope.row.examineeStatus !== 5) ? scope.row.markTeacherName : '/' }}
              </template>
          </el-table-column>
          </el-table>
          <el-pagination
            style="margin-top: 10px"
            @size-change="handleSizeChangeStuPage"
            @current-change="handleCurrentChangeStuPage"
            :current-page="stuPage.pageIndex"
            :page-sizes="[10, 20, 50, 100, 150, 200]"
            :page-size="stuPage.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="stuPage.totalSize"
          ></el-pagination>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 考生弹框 -->
    <el-dialog :visible.sync="stuDialogVisible" title="学生信息" width="500px">
      <div class="stuItem redStar">姓名：{{ stuInfo.studentName }}</div>
      <div class="stuItem redStar">学号：{{ stuInfo.studentCode }}</div>
      <div class="stuItem redStar">毕业年份：{{ stuInfo.graduationYear }}</div>
      <div class="stuItem redStar">
        性别：
        <el-radio-group v-model="stuInfo.gender" disabled>
          <el-radio :label="1">男</el-radio>
          <el-radio :label="0">女</el-radio>
          <el-radio :label="null">未知</el-radio>
        </el-radio-group>
      </div>
      <div class="stuItem redStar">证件类型：{{ stuInfo.idCardType | idCardTypeFilter }}</div>
      <div class="stuItem redStar">证件号码：{{ stuInfo.idCard }}</div>
      <div class="stuItem">
        图片：
        <el-image
          fit="cover"
          :src="stuInfo.url"
          v-if="!!stuInfo.avatarUrl"
          style="width: 100px; height: 100px"
        ></el-image>
        <span v-else>未上传</span>
      </div>
      <div style="text-align: right">
        <el-button
          @click="
            () => {
              this.stuDialogVisible = false;
            }
          "
          >关闭</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getExamDataApi,
  getExamineeDetailApi,
  getExamineeListApi,
  getTeacherListApi,
  getStuBaseInfoApi
} from "@/api/archive/archiveManagement.js";
import { setStore, getStore, removeStore } from '@/utils/token.js'
import dayjs from 'dayjs'
import { isEmpty, formatDate } from '@/utils/util'
export default {
  data() {
    return {
      activeName: "first",
      teacherCondition: {
        teacherName: "",
        teacherCode: "",
        schoolName: "",
        subjectType: 0,
        teacherType: 0,
      },
      teacherData: [],
      teacherPage: {
        pageIndex: 1,
        pageSize: 10,
        totalSize: 0,
      },
      studentCondition: {
        studentName: "",
        examineeCode: "",
        sysOrgSchoolName: "",
        subjectType: 0,
      },
      stuData: [],
      stuPage: {
        pageIndex: 1,
        pageSize: 10,
        totalSize: 0,
      },
      stuDialogVisible: false,
      stuInfo: {
        name: "",
        code: "",
        graduateYear: "",
        gender: 1,
        certificateType: "",
        certificateCode: "",
        url: "",
      },
      rulesArrangeModel: 1,
      archiveInfo: {}, // 已归档考试信息
      examBasicInfo: {},
      showArr: [true, true, true, true],
      tableCondition: {
        area: "",
        examPlace: "",
        examPlaceRoom: "",
      },
      tableData: [],
      tempTableData: [],
      areaList: [],
      examPlaceRoomList: [],
    };
  },
  created() {
    const { archiveInfo } = this.$route.query;
    this.archiveInfo = JSON.parse(archiveInfo); // 已归档考试信息
    this.fetchTeacher();
    this.fetchStundent();
    this.fetchExamBasicInfo();
    if(!!getStore("archiveExamineeExamedStatue")) {
      this.activeName = getStore("archiveExamineeExamedStatue")
    }
  },
  beforeDestroy() {
    if (this.$route.path !== '/examManage/scoreDetails') {
      removeStore("archiveExamineeExamedStatue")
    }
  },
  methods: {
    // 获取考试数据
    fetchExamBasicInfo() {
      getExamDataApi(this.archiveInfo.examArchivesId).then((res) => {
        if (res.success) {
          this.examBasicInfo = res.data;
          this.handleFillTableData();
        }
      });
    },
    // 获取考试教师数据
    fetchTeacher() {
      let params = {
        teacherName: this.teacherCondition.teacherName,
        teacherCode: this.teacherCondition.teacherCode,
        schoolName: this.teacherCondition.schoolName,
        // subjectType: 0,
        // teacherType: 0,
      }
      if(!!this.teacherCondition.subjectType) {
        params = {
          ...params,
          subjectType: this.teacherCondition.subjectType
        }
      }
      if(!!this.teacherCondition.teacherType) {
        params = {
          ...params,
          teacherType: this.teacherCondition.teacherType
        }
      }
      getTeacherListApi(
        this.archiveInfo.examArchivesId,
        this.teacherPage.pageIndex,
        this.teacherPage.pageSize,
        params
      ).then((res) => {
        if (res.success) {
          this.teacherData = res.data;
          this.teacherPage.totalSize = res.total;
        }
      });
    },
    // 获取考试考生数据
    fetchStundent() {
      let params = {
        studentName: this.studentCondition.studentName,
        examineeCode: this.studentCondition.examineeCode,
        sysOrgSchoolName: this.studentCondition.sysOrgSchoolName,
        // subjectType: 0,
        // teacherType: 0,
      }
      if(!!this.studentCondition.subjectType) {
        params = {
          ...params,
          subjectType: this.studentCondition.subjectType
        }
      }
      getExamineeListApi(
        this.archiveInfo.examArchivesId,
        this.stuPage.pageIndex,
        this.stuPage.pageSize,
        params
      ).then((res) => {
        if (res.success) {
          this.stuData = res.data;
          this.stuPage.totalSize = res.total;
        }
      });
    },
    // 填充场次时间数据
    handleFillTableData() {
      this.tableData = [];
      this.tempTableData = [];
      this.examBasicInfo.examArchivesExamTimeAreaViewList.forEach((x) => {
        // 区县
        this.areaList.push(x.sysOrgAreaName);
        x.examArchivesExamTimeAreaPlaceViewList.forEach((y) => {
          // 考点
          y.examArchivesExamTimeAreaPlaceRoomViewList.forEach((z) => {
            // 考场
            this.examPlaceRoomList.push(z.sysOrgExamPlaceRoomName);
            z.examArchivesExamTimeSessionViewList.forEach((item) => {
              // 场次信息
              this.tableData.push({
                examEndTime: item.examEndTime,
                examExamineeNum: item.examExamineeNum,
                examSession: item.examSession,
                examStartTime: item.examStartTime,
                makeup: item.makeup,
                subjectType: item.subjectType,
                sysOrgExamPlaceRoomName: z.sysOrgExamPlaceRoomName,
                sysOrgExamPlaceName: y.sysOrgExamPlaceName,
                sysOrgAreaName: x.sysOrgAreaName,
              });
            });
          });
        });
      });
      this.tempTableData = JSON.parse(JSON.stringify(this.tableData));
    },
    // 查询场次时间
    handleQuery() {
      let arr = JSON.parse(JSON.stringify(this.tempTableData));
      if (this.tableCondition.area) {
        arr = arr.filter((it) => it.sysOrgAreaName == this.tableCondition.area);
      }
      if (this.tableCondition.examPlace) {
        arr = arr.filter((it) =>
          it.sysOrgExamPlaceName.includes(this.tableCondition.examPlace)
        );
      }
      if (this.tableCondition.examPlaceRoom) {
        arr = arr.filter(
          (it) =>
            it.sysOrgExamPlaceRoomName == this.tableCondition.examPlaceRoom
        );
      }
      this.tableData = arr;
    },
    // 展开折叠
    toggleBtn(i) {
      this.showArr[i] = !this.showArr[i];
      this.$forceUpdate()
    },
    // 查询老师
    handleQueryTeacher() {
      this.teacherPage.pageIndex = 1;
      this.teacherPage.pageSize = 10;
      this.fetchTeacher();
    },
    // 重置老师
    handleResetTeacher() {
      this.teacherCondition = {
        teacherName: "",
        teacherCode: "",
        schoolName: "",
        subjectType: 0,
        teacherType: 0,
      };
      this.handleQueryTeacher();
    },
    // 分页老师
    handleSizeChangeTeacherPage(val) {
      this.teacherPage.pageIndex = 1;
      this.teacherPage.pageSize = val;
      this.fetchTeacher();
    },
    handleCurrentChangeTeacherPage(val) {
      this.teacherPage.pageIndex = val;
      this.fetchTeacher();
    },
    // 查询学生
    handleQueryStudent() {
      this.stuPage.pageIndex = 1;
      this.stuPage.pageSize = 10;
      this.fetchStundent();
    },
    // 重置学生
    handleResetStudent() {
      this.studentCondition = {
        studentName: "",
        examineeCode: "",
        sysOrgSchoolName: "",
        subjectType: 0,
      };
      this.fetchStundent();
    },
    // 分页学生
    handleCurrentChangeStuPage(val) {
      this.stuPage.pageIndex = val;
      this.fetchStundent();
    },
    handleSizeChangeStuPage(val) {
      this.stuPage.pageIndex = 1;
      this.stuPage.pageSize = val;
      this.fetchStundent();
    },
    // 点击学生名字
    handleExamineeNameClick(row) {
      getStuBaseInfoApi(row.examArchivesExamineeId).then(res => {
        if(res.success) {
          this.stuInfo = {
            ...res.data,
            url: this.$imageAddress(res.data.avatarUrl)
          }
          this.stuDialogVisible = true;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 点击查看考生数据详情
    handleCheckExamData(row, isMarked) {
      if(row.examineeStatus !== 3 && isMarked) {
        this.$message.error("未全部阅卷完毕，暂不支持查看!")
        return
      }
      // setStore('archiveExamineeExamedStatue', this.activeName)
      this.$router.push({
        path: '/archive/examineeVideo',
        query: {
          achievementSummaryId: 0,
          examId: this.archiveInfo.examId,
          subject: row.subjectType,
          examArchivesExamineeId: row.examArchivesExamineeId,
          examineeInfo: JSON.stringify(row),
          isMarked
        },
      })
    },
    //格式化内容
    customFormatter(row, column) {
      if (isEmpty(row)) {
        return
      }
      switch (column.property) {
        case 'examStartTime':
          if (isEmpty(row.examStartTime)) {
            return '/'
          }
          return formatDate(new Date(row.examStartTime), 'hh:mm')
        case 'examEndTime':
          if (isEmpty(row.examEndTime)) {
            return '/'
          }
          return formatDate(new Date(row.examEndTime), 'hh:mm')
        case 'score':
          if (row.examineeStatus === 4 || row.examineeStatus === 5) {
            return '/'
          }
          if(row.score === 0.00 && row.examineeStatus !== 3) {
            return "未公布成绩"
          }
          return row.score
      }
    },
  },
  filters: {
    dayList(val) {
      return dayjs(val).format("YYYY-MM-DD HH:mm");
    },
    examModelFilters(key) {
      switch (key) {
        case 1:
          return `无试卷`;
        case 2:
          return `电子试卷`;
        case 3:
          return `实验报告拍照`;
        default:
          return ``;
      }
    },
    subjectType(key) {
      switch (key) {
        case 1:
          return `物理`;
        case 2:
          return `生物`;
        case 3:
          return `化学`;
        default:
          return `subjectType值为${key}`;
      }
    },
    arrangeModelFilters(key) {
      switch (key) {
        case 1:
          return `定时定点`;
        case 2:
          return `随到随考`;
        case 3:
          return `定点不定时`;
        default:
          return `未选排考方式`;
      }
    },
    teacherTypeFilter(key) {
      switch (key) {
        case 1:
          return `监考组长`;
        case 2:
          return `监考老师`;
        case 3:
          return `阅卷组长`;
        case 4:
          return `阅卷老师`;
      }
    },
    examineeStatusFilter(key) {
      switch (key) {
        case 1:
          return `未交卷`;
        case 2:
          return `待阅卷`;
        case 3:
          return `已阅卷`;
        case 4:
          return `缺考`;
        case 5:
          return `取消考试`;
      }
    },
    idCardTypeFilter(val) {
      switch (val) {
        case 1:
          return `身份证`;
        case 2:
          return `港澳居民往来内地通行证`;
        case 3:
          return `台湾居民往来内地通行证`;
        case 4:
          return `护照`;
        default:
          return `未知`;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.title {
  display: flex;
  justify-content: space-between;
  border-left: 10px solid rgb(24, 144, 255);
  overflow: hidden;
  padding: 0 30px 0 50px;
  background-color: rgb(242, 242, 242);
  .title-left {
    display: flex;
    height: 80px;
    line-height: 80px;
  }
  .title-right {
    line-height: 80px;
  }
  .title-left-contentOne {
    font-size: 20px;
    font-weight: 700;
  }
  .title-left-contentTwo {
    line-height: 82px;
    margin-left: 4px;
    color: rgb(170, 170, 170);
    font-weight: 700;
  }
}
.cardBox {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  border: 1px solid #dfe6ec;
  margin-bottom: 20px;
  .cardTit {
    box-sizing: border-box;
    font-size: 20px;
    font-weight: bold;
    border-bottom: 1px #dfe6ec solid;
    margin-bottom: 20px;
    vertical-align: middle;
    i {
      cursor: pointer;
    }
    span {
      box-sizing: border-box;
      display: inline-block;
      margin: 0 8px;
      width: 36px;
      height: 36px;
      font-size: 16px;
      line-height: 36px;
      text-align: center;
      border-radius: 50%;
      border: 1px solid #dfe6ec;
      font-weight: normal;
      margin-bottom: 8px;
    }
  }
  .cardContent {
    overflow: hidden;
  }
}
.stuItem {
  margin-bottom: 20px;
  font-size: 16px;
  margin-left: 20px;
  &.redStar {
    position: relative;
    &::after {
      content: "*";
      color: gray;
      position: absolute;
      left: -10px;
      top: 0;
    }
  }
}
table {
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  border-color: #ebeef5;
}
td {
  padding: 10px;
}
.divText {
  text-align: left;
  box-sizing: border-box;
}
.cardContentRow {
  margin-bottom: 10px;
}
.bgColor {
  background: rgba(223, 230, 236, 1);
  border: 2px solid rgb(215, 215, 215);
}
</style>
